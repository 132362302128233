import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { AppComponent } from 'src/app/app.component';
import { FunctionSwitchHelperService } from 'src/app/providers/component-helpers/function-switch.service';
import { SystemHelper } from 'src/app/providers/helpers/system-helper';
import { UserHelper } from 'src/app/providers/helpers/user-helper.service';
import { FAILED_REQUEST_MONITORING } from 'src/assets/constants/functionSwitch.constants';
import { DevelopmentHelper } from '../../helpers/development.helper';
import { FAILED_REQUEST_MONITORING_BASE_ROUTE } from 'src/packages/failedRequestMonitoring/failedRequestMonitoring.routes';
import { SyncWrapperService } from 'src/app/providers/syncWrapper.service';

@Component({
  selector: 'dev-options',
  templateUrl: './dev-options.html',
})
export class DevOptions implements OnInit {
  _failedRequestMonitoringEnabled?: boolean;

  get FAILED_REQUEST_MONITORING_BASE_ROUTE() {
    return FAILED_REQUEST_MONITORING_BASE_ROUTE;
  }
  protected isValidating = false;
  constructor(
    public readonly appComp: AppComponent,
    public readonly devHelper: DevelopmentHelper,
    public readonly systemHelper: SystemHelper,
    public readonly userHelper: UserHelper,
    private readonly functionSwitchHelper: FunctionSwitchHelperService,
    private readonly popoverCtrl: PopoverController,
    private readonly router: Router,
    private readonly syncWrapper: SyncWrapperService
  ) {
    if (this.systemHelper.isDeveloper)
      this.devHelper.activateDeveloperModeCounter = 0;
  }

  ngOnInit(): void {
    this._failedRequestMonitoringEnabled = this.functionSwitchHelper.has(
      FAILED_REQUEST_MONITORING
    );
  }

  open(url: string) {
    this.popoverCtrl.dismiss();
    this.router.navigateByUrl(url);
  }

  async reloadApp() {
    this.popoverCtrl.dismiss();
    await this.router.navigateByUrl('/')
    this.performReload();
  }

  performReload() {
    window.location.reload();
  }

  /**
   * Initiiert die Offlinedaten-Validierung
   */
  async validateLocalData(): Promise<void> {
    this.popoverCtrl.dismiss();
    if (!this.isValidating){
      this.isValidating = true;
      await this.syncWrapper.validateLocalData();
      this.isValidating = false;
    }
  }

  exitDevMode() {
    this.popoverCtrl.dismiss();
    this.devHelper.clickAppVersion();
  }
}
