// tslint:disable: no-console
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
// internal
import { BusinessModel } from 'src/app/models/settings/business';
import {
  MandatorModel,
  mapHomePageEnumToPath,
} from 'src/app/models/settings/mandator';
import { SettingsModel } from 'src/app/models/settings/settings';
import { UserModel } from 'src/app/models/users/user';
import { EventsService } from 'src/app/providers/events.service';
import { BusinessService } from 'src/app/providers/model-services/business.service';
import { MandatorService } from 'src/app/providers/model-services/mandator.service';
import { UserService } from 'src/app/providers/model-services/user.service';
import { CarService } from 'src/packages/carManagement';
import { SystemHelper } from './system-helper';
import { EditingMaskModel, MaskModel } from 'src/app/models/editing';

const ORDER_BLOCKS = [
  'Durchzuführende Arbeiten',
  'Auftragsinformationen',
  'Bufferstocks',
  'Maschine',
  'Positionen',
  'Checklisten',
  'Durchgeführte Arbeiten',
  'Abschluss',
];
const ASSEMBLY_REPORT_BLOCKS = [
  'Durchzuführende Arbeiten',
  'Maschinendaten',
  'Checklisten',
  'Positionen',
  'Gesamtzählerstand',
  'Kundenrückerstattung',
  'Wechselgeld',
  'Bargeldverwaltung',
  'Abgeschlossene/Ausstehende Arbeiten',
  'Offene Schäden',
];

export interface IMandatorBusinessResponse {
  mandator: MandatorModel;
  business: BusinessModel;
}

@Injectable({
  providedIn: 'root',
})
export class UserHelper {
  /** Currently logged in user */
  private currentUser: UserModel;
  /** Currently selected Mandator */
  private currentMandator: MandatorModel;
  /** Currently selected business */
  private currentBusiness: BusinessModel;
  /** Current settings wich are filtered */
  private settings: SettingsModel;

  // Zeigt an ob der User geladen wurde
  _userLoaded: boolean = false;
  // Zeigt als BehaviorSubject an ob der User geladen wurde
  private _userLoadedBS: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  // Observable für den Ladezustand des Users
  public userLoaded$: Observable<boolean> = this._userLoadedBS.asObservable();

  constructor(
    private readonly businessService: BusinessService,
    private readonly carService: CarService,
    private readonly events: EventsService,
    private readonly mandatorService: MandatorService,
    private readonly userService: UserService,
    private readonly systemHelper: SystemHelper,
    private readonly keycloakService: KeycloakService
  ) {
    this.loadUser();
    this.systemHelper.isInLogoutProcess = false;
  }

  /**
   * sets the currently logged in user
   * @param user to set
   */
  public setUser(user: UserModel): void {
    this.currentUser = user;
    Sentry.setUser(
      user
        ? {
            username: user.username,
            email: user.email,
            id: user.id.toString(),
            ip_address: '{{auto}}',
          }
        : null
    );
  }

  /**
   * Get the currently logged in user
   * @returns logged in user
   */
  public getUser(minified?: boolean): UserModel {
    if (minified) {
      return {
        id: this.currentUser.id,
        username: this.currentUser.username,
        firstname: this.currentUser.firstname,
        lastname: this.currentUser.lastname,
        email: this.currentUser.email,
        keycloak_username: this.currentUser.keycloak_username,
        is_admin: this.currentUser.is_admin,
        car: {
          id: this.currentUser.car?.id,
          current_user_id: this.currentUser.car?.current_user_id,
          last_mileage: this.currentUser.car?.last_mileage,
        },
      } as UserModel;
    }
    return this.currentUser;
  }

  /**
   * Generates a readable identity of the provided user
   * @param user User to identify
   * @returns indentity as a string
   */
  public getUserString(user: UserModel): string {
    if (!user) return 'No User';
    if (user.lastname?.length > 0) {
      if (user.firstname?.length > 0) {
        return user.firstname + ' ' + user.lastname;
      } else {
        return user.lastname;
      }
    } else if (user.username?.length > 0) {
      return user.username;
    } else if (user.email?.length > 0) {
      return user.email;
    } else if (user.keycloak_username?.length > 0) {
      return user.keycloak_username;
    } else {
      return 'No Identity';
    }
  }

  /**
   * Get the currently selected mandator and business
   * @returns mandator and business
   */
  public getManBus(): IMandatorBusinessResponse {
    return {
      mandator: this.currentMandator,
      business: this.currentBusiness,
    };
  }

  /**
   * Gibt die Startseite des Mandanten zurück
   * - Falls diese nicht definiert ist, wird die url der SyncQueue (dashboard-worker) zurückgegeben
   */
  public getHomePage(): string {
    return mapHomePageEnumToPath(this.currentMandator?.home_page);
  }

  /**
   * Get the currently settings to use
   * @returns settings
   */
  public getSettings(): SettingsModel {
    return this.settings;
  }

  /**
   * Hat der Aktuell angemeldete Nutzer die Rolle 'Innendienst'?
   * @returns true, wenn der Nutzer die Rolle 'Innendienst' hat
   */
  public get isInside(): boolean {
    return !!this.currentUser?.groups.find(
      (g) => g.group_name === 'Innendienst'
    );
  }

  /**
   * Hat der Aktuell angemeldete Nutzer die Rolle 'Aussendienst'?
   * @returns true, wenn der Nutzer die Rolle 'Aussendienst' hat
   */
  public get isOutside(): boolean {
    return !!this.currentUser?.groups.find(
      (g) => g.group_name === 'Aussendienst'
    );
  }

  /**
   * Hat der Aktuell angemeldete Nutzer die Rolle 'Befüllung'?
   * @returns true, wenn der Nutzer die Rolle 'Befüllung' hat
   */
  public get isFiller(): boolean {
    return !!this.currentUser?.groups.find((g) => g.group_name === 'Befüllung');
  }

  /**
   * Hat der Aktuell angemeldete Nutzer die Rolle 'Service'?
   * @returns true, wenn der Nutzer die Rolle 'Service' hat
   */
  public get isService(): boolean {
    return !!this.currentUser?.groups.find(
      (g) => g.group_name === 'Servicemitarbeiter'
    );
  }

  /**
   * Is the current user a admin?
   * @returns if is admin
   */
  public get isAdmin(): boolean {
    return this.currentUser?.is_admin;
  }

  /**
   * Durchsucht die Gruppen des aktuellen Nutzers nach Bearbeitungsmasken für die Maschine
   */
  public get assemblyReportEditMask(): EditingMaskModel | undefined {
    return this.currentUser.groups
      ?.map((grp) => grp.editing_masks)
      .reduce((acc, masks) => acc.concat(masks), [])
      .find((em) => em.mask_type === 'Montagebericht') ?? this.defaultEditMask('Montagebericht')
  }
  /**
   * Durchsucht die Gruppen des aktuellen Nutzers nach Bearbeitungsmasken für den Serviceauftrag
   */
  public get orderEditMask(): EditingMaskModel {
    return this.currentUser.groups
      ?.map((grp) => grp.editing_masks)
      .reduce((acc, masks) => acc.concat(masks), [])
      .find((em) => em.mask_type === 'Serviceauftrag') ?? this.defaultEditMask('Serviceauftrag')
  }

  /**
   * Erstellt eine Standardmaske für Serviceauftrag oder Montagebereicht
   * @constant ORDER_BLOCKS 
   * @constant ASSEMBLY_REPORT_BLOCKS
   * @param mask_type 
   * @returns 
   */
  private defaultEditMask(mask_type: 'Serviceauftrag' | 'Montagebericht') :EditingMaskModel {
    const selectedBlock = mask_type == 'Serviceauftrag' ? ORDER_BLOCKS : ASSEMBLY_REPORT_BLOCKS
    let masks: MaskModel[] = []
    selectedBlock.forEach((b, index) => {
      masks.push({
        position: index +1,
        block: b
      } as MaskModel)
    })
    return {
      mask_type: mask_type,
      masks: masks,
    } as EditingMaskModel
  }


  /**
   * Benutzer abmelden, Tokens resetten, Keycloak abmelden
   */
  public logoutFull() {
    this.systemHelper.isInLogoutProcess = true;
    this.resetUser();
    localStorage.removeItem('kc_refreshToken');
    localStorage.removeItem('kc_token');
  }

  /**
   * Trys to set the currently selected mandator
   * @param mandator Mandator to select
   * @returns successfully changed mandator?
   */
  public async setMandator(mandator: MandatorModel): Promise<boolean> {
    if (!mandator) return false;
    this.currentMandator = await firstValueFrom(
      this.mandatorService.find(mandator.id)
    );
    this.currentUser.mandator = this.currentMandator;
    this.currentUser.businesses = (
      await firstValueFrom(
        this.businessService.where({ mandator_id: this.currentMandator.id })
      )
    ).data;
    return true;
  }

  /**
   * Trys to set the currently selected business
   * @param business Business to select
   * @returns successfully changed business?
   */
  public setBusiness(business: BusinessModel): boolean {
    if (this.currentUser?.businesses.includes(business)) {
      this.currentBusiness = business;
      return true;
    }
    return false;
  }

  /**
   * Loads the current user
   */
  public async loadUser(): Promise<UserModel> {
    let resUser: UserModel | undefined;
    try {
      resUser = await firstValueFrom(this.userService.current_user());
    } catch (error) {
      if (error.status === 401) {
        console.warn('[UserHelper] Sitzung abgelaufen, melde ab.');
        this.logoutFull();
        this.keycloakService.logout(window.location.origin);
      } else {
        console.error('[UserHelper] Nutzer kann nicht geladen werden:', error);
      }
      return undefined;
    }

    this.setUser(resUser);
    this.events.publish('userHelper:loadedUser', this.currentUser);
    this._userLoadedBS.next(true);
    this._userLoaded = true;
    try {
      resUser.car = resUser.car
        ? await firstValueFrom(this.carService.find(resUser.car.id))
        : null;
      console.debug('[UserHelper] Car for user got loaded!', resUser.car);
    } catch (error) {
      console.warn(
        'Could not load car for user, if sync is in progress, will try again after sync!',
        error
      );
      return resUser;
    }
    this.currentMandator = resUser.mandator;
    this.currentBusiness = resUser.businesses[0];
    this.generateSettings();
    return this.currentUser;
  }

  /**
   * Resets all UserData (for Logout)
   */
  public resetUser(): void {
    this.setUser(null);
  }

  /**
   * Regenerates the settings wich are currently active for the user
   */
  private generateSettings() {
    const generated: SettingsModel = {} as SettingsModel;
    if (!this.currentBusiness?.settings) {
      this.settings = this.currentMandator.settings;
      return;
    }
    Object.keys(this.currentBusiness.settings).forEach((k) => {
      const value = this.currentBusiness.settings[k];
      if (value) {
        generated[k] = value;
      } else {
        generated[k] = this.currentMandator.settings[k];
      }
    });
    this.settings = generated;
  }
}
