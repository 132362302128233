import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { StoredData } from '@vending/sync-engine-client/lib/models/storedData';
import { firstValueFrom } from 'rxjs';
import { AssemblyReportModel } from 'src/app/models/assembly_report';
// internal
import { CustomerModel } from 'src/app/models/customers/customer';
import { MachineModel } from 'src/app/models/machine';
import { OrderCategory, OrderModel } from 'src/app/models/order';
import { AddressModel } from 'src/app/models/system/address';
import { UserModel } from 'src/app/models/users/user';
import { OrderState } from 'src/app/pages/processing/orders/order-details/helper';
import { OrderDetailsPageQueryParams } from 'src/app/pages/processing/orders/order-details/order-details.page';
import { ToastService } from 'src/app/providers/component-helpers/toast.service';
import { ChecklistsHelper } from 'src/app/providers/helpers/checklists-helper.service';
import { LoadingHelper } from 'src/app/providers/helpers/loading-helper.service';
import { SystemHelper } from 'src/app/providers/helpers/system-helper';
import { UserHelper } from 'src/app/providers/helpers/user-helper.service';
import { OrderService } from 'src/app/providers/model-services/orders/order.service';
import { StatusService } from 'src/app/providers/model-services/status.service';
import { GlobalHelper } from 'src/packages/mitsBasics/helpers/globalHelper/global.helper';

// Standard Status für einen neuen Auftrag; wird im StatusService zur Suche des Status für einen neuen Auftrag verwendet
const NEW_ORDER_DEFAULT_STATE_NAME: OrderState = 'Offen';
// Verwendete Auftragsnummer für einen neuen (Notfall)-Auftrag
const NEW_ORDER_DEFAULT_NUMBER = '!99999';

@Injectable({
  providedIn: 'root',
})
export class NewOrderHelper {

  constructor(
    // ANGULAR / IONIC
    private readonly router: Router,
    private readonly alertCtrl: AlertController,
    // OFFLINE DATA SERVICES
    private readonly orderService: OrderService,
    private readonly statusService: StatusService,
    // HELPER SERVICES
    private readonly loadingHelper: LoadingHelper,
    private readonly checklistsHelper: ChecklistsHelper,
    private readonly systemHelper: SystemHelper,
    private readonly toastService: ToastService,
    private readonly userHelper: UserHelper,
  ) {
  }

  /**
   * Funktion zum Speichern eines neuen Auftrags (OrderModel) und anschließender Navigtaion zur OrderDetailsPage
   * @param order OrderModel des Auftrags, der gespeichert werden soll
   * @param quickStart boolean, ob der Montagebericht der Maschine direkt geöffnet werden soll
   * @param machine MachineModel der Maschine, für die ein Montagebericht (AssemblyReport) im Auftrag hinterlegt wird
   * @returns Promise<OrderModel> der gespeicherte Auftrag
   * @usedBy Pages: EmergencyOrderPage
   * @usedBy Services: MachineHelper
   * @uses Services: LoadingHelper, ChecklistsHelper, OrderService, StatusService, Router
   * @uses HelperMethods: appendAddressToOrder(), navigateToOrder()
   */
  async saveEmergencyOrder(
    order: OrderModel,
    quickStart: boolean,
    machine?: MachineModel,
  ): Promise<OrderModel> {
    this.loadingHelper.removeLoading();
    // Auftragsart (Befüllung/Service) anhand Nutzerrolle ermitteln bzw. vom Nutzer abfragen (#627)
    order.order_category = await this.getOrderCategory();

    this.loadingHelper.setText($localize`Erstelle Auftrag. Bitte warten...`);
    this.loadingHelper.addLoading();

    // Setzt Adresse des Maschinenstandorts, oder Standardadresse des Kunden im übergebenen Auftrag
    const customerDefaultAddress: AddressModel = this.customerDefaultAddress(order);
    this.appendAddressToOrder(
      order,
      machine ? machine.customer_location : customerDefaultAddress,
    );

    // Setzt IWriteableChecklist[]
    order.writeable_checklists = await this.checklistsHelper.mapAndDeleteIds(
      order.writeable_checklists,
      'Order',
      order.id,
    );

    // Setzt StatusModel
    order.state = (
      await firstValueFrom(
        this.statusService.where({name: NEW_ORDER_DEFAULT_STATE_NAME})
      )
    ).data[0];
    order.state_id = order.state.id;
    order.state_name = order.state.name;

    // Versuche, den Auftrag direkt remote zu speichern, wenn online
    if (this.systemHelper.isOnline) {
      const originalOrder = { ...order }; // Tiefe Kopie von der Order, falls saveRemote fehlschlägt
      order = await firstValueFrom(this.orderService.saveRemote(order))
        .catch((err) => {
          console.error('[NewOrderHelper] saveEmergencyOrder(): Auftrag konnte REMOTE nicht gespeichert werden', err);
          return undefined;
        });
      // Falls der Auftrag online nicht gespeichert werden konnte, ebenfalls zur lokalen Speicherung vorbereiten
      if (!order) {
        this.prepareLocalOrder(originalOrder); // Aufruf mit der originalOrder
        order = originalOrder;
      }
    } else {
      this.prepareLocalOrder(order);
    }
    // Speichere den Auftrag lokal
    order = await this.orderService.saveLocalForce(order)
      .then((storedData: StoredData<OrderModel>) => storedData.content)
      .catch((err) => {
        console.error('[NewOrderHelper] saveEmergencyOrder(): Auftrag konnte LOKAL nicht gespeichert werden', err);
        return undefined;
      });
    // Falls der Auftrag nicht gespeichert werden konnte, Fehlermeldung anzeigen
    if (!order) {
      await this.orderFailureAlert();
      this.loadingHelper.removeLoading();
      return;
    }
    if (quickStart) await this.navigateToOrder(order, machine);
    else await this.navigateToOrder(order);
    this.loadingHelper.removeLoading();
    return order;
  }

  /**
   * Gibt die Standardadresse des Kunden zurück, falls vorhanden, ansonsten die Adresse des ersten Maschinenstandorts
   * @param order - der Auftrag, für den die Adresse ermittelt wird
   * @private
   */
  private customerDefaultAddress(order: OrderModel): AddressModel | null {
    if(order?.customer?.default_address?.length > 0) {
      return order.customer.default_address[0];
    }
    if(order?.assembly_reports?.length >= 1) {
      return order.assembly_reports.find(
          (ar: AssemblyReportModel) => ar.machine?.customer_location?.id)?.machine?.customer_location || null;
    }
    return null
  }

  /***
   * Funktion zum Navigieren zu einem Auftrag (OrderModel)
   * - Fügt den Query-Parameter 'quickStart=true' hinzu
   * - Fügt den Query-Parameter 'machine_id' hinzu, falls eine Maschine übergeben wurde
   * - Navigiert zur Detailansicht des übergebenen Auftrags (OrderDetailsPage)
   * @param order Der Auftrag, zu dem navigiert werden soll
   * @param machine Die Maschine, für die ein Montagebericht (AssemblyReport) im Auftrag hinterlegt ist
   * @returns Promise<void>
   * @usedBy Services: MachineHelper
   * @usedBy saveEmergencyOrder()
   * @uses Services: Router
   */
  async navigateToOrder(order: OrderModel, machine?: MachineModel): Promise<void> {
    const queryParams: OrderDetailsPageQueryParams = {
      quickStart: 'true',
      machine_id: machine?.id ? machine.id.toString() : undefined,
    };
    await this.router.navigate(['orders/overview/details', order.id], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  /**
   * Funktion zur Erzeugung eines neuen Objekts für einen Auftrag (OrderModel)
   * - Ermittelt den Kunden über die Maschine, falls kein Kunde übergeben wurde
   * - Unterbindet die Erstellung des Auftrags, wenn kein Kunde gefunden wurde, falls skipErrors auf false gesetzt ist
   * - Erzeugt ein neues OrderModel-Objekt und gibt dieses zurück
   *   - Dieses enthält möglicherweise keinen Kunden und keine Maschine
   * @param user der Benutzer, dem der Auftrag zugeordnet wird
   * @param machine die Maschine, für die ein Montagebericht (AssemblyReport) im Auftrag hinterlegt wird
   * @param customer CustomerModel des Kunden, für den der Auftrag erstellt wird
   * @param skipErrors boolean, ob Fehler übersprungen werden sollen,
   * auch wenn kein Kunde übergeben oder über die Maschine gefunden wurde
   * @returns OrderModel das erzeugte Objekt für den Auftrag
   * @usedBy Services: MachineHelper
   * @usedBy Pages: EmergencyOrderPage, ArticleSearchDebuggerPage
   * @uses Services: ToastService
   * @uses HelperMethods: appendAddressToOrder(), customerFailureAlert()
   */
  public newOrder(
    user: UserModel,
    machine?: MachineModel,
    customer?: CustomerModel,
    skipErrors: boolean = false,
  ): OrderModel {
    // Ermittelt den Kunden über die Maschine, falls kein Kunde übergeben wurde
    if (!customer) {
      customer = machine?.customer;
      if (!customer && !skipErrors) {
        this.customerFailureAlert();
        return null;
      }
    }

    // Erzeugt ein neues OrderModel-Objekt
    const order: OrderModel = {
      number: NEW_ORDER_DEFAULT_NUMBER,
      field_user_ids: [user.id],
      order_field_users: [
        {
          user_id: user.id,
          firstname: user.firstname,
          lastname: user.lastname,
        },
      ],
      created_by_id: user.id,
      customer: customer || {} as CustomerModel, // Falls Kunde nicht vorhanden, customer auf leeres Objekt setzen
      customer_id: customer?.id, // Falls Kunde nicht vorhanden, customer_id auf null setzen
      date: new Date().toISOString(),
      execution_date: new Date().toISOString(),
      assembly_reports: machine // Falls Maschine Vorhanden, für ersten Assembly-Report setzen
        ? [
          {
            machine_id: machine.id,
            machine,
          },
        ]
        : [],
      writeable_checklists: [],
      description: machine
        ? 'Auftrag wurde automatisch aus dem Schnelleinstieg angelegt für die Maschine: ' +
        machine.name
        : null,
    } as OrderModel;
    this.appendAddressToOrder(order, machine?.customer_location, skipErrors);
    return order;
  }


  ////////////////////////// HILFSMETHODEN //////////////////////////

  /**
   * Hilfsfunktion zur Ermittlung der Auftragsart (OrderCategory) für den Auftrag (#627)
   * - ist der Benutzer der Gruppe "Befüllung" zugerodnet, wird 'FILLING' als OrderCategory zurückgegeben
   * - ist der Benutzer der Gruppe "Service" zugeordnet, wird 'SERVICE' als OrderCategory zurückgegeben
   * - Ansonsten wird der Benutzer befragt
   * @private
   * @returns Promise<OrderCategory> die ermittelte Auftragsart
   */
  private async getOrderCategory(): Promise<OrderCategory> {
    if(this.userHelper.isFiller) return 'FILLING';
    if(this.userHelper.isService) return 'SERVICE';
    else return await this.showAlertToChooseOrderCategory();
  }

  /**
   * Hilfsfunktion zur Anzeige eines Alerts, um die Auftragsart (OrderCategory) auszuwählen
   * @returns Promise<OrderCategory> die ausgewählte Auftragsart
   */
  private async showAlertToChooseOrderCategory(): Promise<OrderCategory> {
    const alert: HTMLIonAlertElement = await this.alertCtrl.create({
      header: 'Auftragsart auswählen',
      subHeader: 'Soll Befüllauftrag, oder ein Serviceauftrag erstellt werden?',
      buttons: [
        {
          text: 'Befüllauftrag',
          role: 'FILLING',
        },
        {
          text: 'Serviceauftrag',
          role: 'SERVICE',
        },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role as OrderCategory;
  }

  /**
   * Hilfsfunktion zum Vorbereiten eines offline Auftrags (OrderModel) für Offline-Speicherung
   * @param order - Auftrag, der vorbereitet wird
   * @private
   * @usedBy saveEmergencyOrder()
   * @uses Services: OrderService
   */
  public prepareLocalOrder(order: OrderModel): void {
    order.id = this.orderService.getRandomId();
    order.position_disposals = [];
    order.positions = [];
    order.writeable_checklists = [];
    order.timelogs = [];
    if (!order.assembly_reports) order.assembly_reports = [];
    else
      order.assembly_reports.forEach((ar: AssemblyReportModel): void => {
        if (!ar.writeable_checklists) ar.writeable_checklists = [];
        if (!ar.writeable_sanitation_checklists)
          ar.writeable_sanitation_checklists = [];
        if (!ar.working_step_checklists) ar.working_step_checklists = [];
        if (!ar.positions) ar.positions = [];
        if (!ar.position_disposals) ar.position_disposals = [];
      });
  }

  /**
   * Hilfsmethode zum Hinzufügen der Rechnungsadresse (order.invoice_address) und
   * Montageadresse (order.assemby_address) zu einem Auftrag
   * @param order - Auftrag, dem die Adresse hinzugefügt wird
   * @param address - Adresse, die dem Auftrag hinzugefügt wird
   * @param skipAlert - boolean, ob Toast-Hinweis übersprungen werden soll, wenn keine Adresse gefunden wurde, default: false
   * @private
   * @returns OrderModel - Auftrag mit hinzugefügten Adressen
   * @usedBy saveEmergencyOrder(), newOrder()
   */
  private appendAddressToOrder(
    order: OrderModel,
    address?: AddressModel,
    skipAlert: boolean = false,
  ): OrderModel {
    if (address) {
      order.invoice_address = JSON.parse(JSON.stringify(address));
      delete order.invoice_address.id;
      order.assembly_address = GlobalHelper.deepClone(address);
      delete order.assembly_address.id;
    } else {
      order.invoice_address = {} as AddressModel;
      order.assembly_address = {} as AddressModel;
      if (!skipAlert) {
        this.addressFailureAlert();
      }
    }
    return order;
  }

  /**
   * Hilfsfunktion zur Anzeige einer Fehlermeldung, wenn der Auftrag nicht erstellt werden konnte
   * @private
   * @usedBy saveEmergencyOrder()
   */
  private async orderFailureAlert(): Promise<void> {
    await this.toastService.danger($localize`Der Auftrag konnte nicht erstellt werden.`);
  }

  /**
   * Hilfsfunktion zur Anzeige einer Fehlermeldung, wenn keine Adresse gefunden wurde
   * @private
   * @usedBy appendAddressToOrder()
   */
  private async addressFailureAlert(): Promise<void> {
    await this.toastService.custom(
      $localize`Es wurde keine Addresse gefunden.`,
      'warning',
      10000,
      [
        {
          icon: 'close-outline',
          role: 'cancel',
        },
      ],
    );
  }

  /**
   * Hilfsfunktion zur Anzeige einer Fehlermeldung, wenn kein Kunde gefunden wurde
   * @private
   * @usedBy newOrder()
   */
  private async customerFailureAlert(): Promise<void> {
    await this.toastService.custom(
      $localize`Es wurde kein Kunde gefunden und der Auftrag nicht erstellt!`,
      'danger',
      10000,
      [
        {
          icon: 'close-outline',
          role: 'cancel',
        },
      ],
    );
  }
}
