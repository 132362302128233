/**
 * System
 */
export const CAR_MANAGEMENT = 'CarManagement';
export const SERVICE_ORDERS = 'Serviceaufträge';
export const ONLY_NATIVE_SCANNER =
  'Nur native Barcodescanner Nutzung erlauben?';
export const OFFLINE_STORAGE_UPDATE_INTERVAL =
  'Abrufintervale für den offline Speicher';
export const TOUR_MANAGEMENT = 'Tourenplanung';
export const FAILED_REQUEST_MONITORING = 'Request-Monitoring';
export const NEGATIVE_POSITIONS = 'Negativbestände auf Fahrzeug/Lager möglich?';
export const FULLTEXT_SUBSTRINGS =
  'Sollen Teilstrings beim Fulltext verwendet werden?';

/**
 * Benutzerkonten
 */
export const CHANGEABLE_EMPLOYEE_ID = 'Personalnummern änderbar?';
export const CHANGEABLE_PASSWORD = 'Passwort änderbar?';

/**
 * Montagebericht
 */
export const REPORT_SHOW_DISTANCE = 'Gefahrene Kilometer auf Bericht Rendern';

/**
 * CarManagement
 */
export const START_END_MILEAGE_CARS =
  'Sollen start und end Kilometerstände erfasst werden?';
export const CONFIRMATION_POSITION_CAR_ORDERS =
  'Bestätigung pro Position am Auto benötigt?';
export const DISABLE_STORNO_OF_CAR_ORDERS =
  'Keine Stornierung von Bestellungen möglich';
export const LOADING_ORDERS_AMOUNT_CHANGES =
  'Beladen nach Bestellungen - Mengenänderung durch Außendienst erlauben';
export const UNLOADING_LOAD_ARTICLES =
  'Fahrzeug Entladen - Artikel vom Fahrzeug vorausfüllen';
export const UNLOADING_SEGMENT_LOADING_ACTIVE =
  'Fahrzeug Entladen - Segment "Beladen" anzeigen';
export const CARS_MIN_MINUTES_BETWEEN_RELOADS =
  'Fahrzeuge erst ab X Minuten erneut aus dem Backend aktualisieren';
export const CM_SHOW_ONLY_IN_COMPLETE_ORDERS_FOR_LOADING =
  'Nur abgeschlossene und unvollständige Bestellungen zur Beladung vorschlagen';
export const CM_CAN_CHECK_ALL_POSITIONS_LOADING =
  'Alle Positionen einer Bestellung direkt zur Beladung bestätigen können';

/**
 * Machine
 */
export const MACHINE_PLANOGRAMS =
  'Können an Maschinen Planogramme hinterlegt werden?';
export const MACHINE_CUSTOMERNUMBER_ONLY_CREATE =
  'Kundennummer nur beim Erstellen frei zur Eingabe?';
export const MACHINE_DISPLAY_REFERENCE = 'Referenz anzeigen';
export const MACHINE_ALLOW_SCAN_OF_SCAN_CODE =
  'Scanerfassung Scan-Code erlauben';
export const MACHINE_ALLOW_SCAN_OF_SERIALNUMBER =
  'Scanerfassung Seriennummer erlauben';
export const MACHINE_ENABLE_DEFAULT_CHECKLIST =
  'Kann an Maschinen eine Standard-Checkliste hinterlegt werden?';
export const MACHINE_ALLOW_CREATE = 'Aussendienst kann Maschinen erstellen';

/**
 * Artikel
 */
export const ARTICLE_OVERPACK_ACTIVE = 'Artikelumverpackungen aktivieren?';
export const ARTICLE_SELECTION_SEARCH_SHOULD_STAY =
  'Artikelauswahl soll die Sucheingabe nach Auswahl eines Artikels beibehalten';
export const ARTICLE_SELECTION_SEARCH_INSTANT_SELECT =
  'Artikelauswahl soll bei nur einem Suchergebnis direkt auswählen';
export const ARTICLE_ENABLE_TAKEBACK = 'Rücknahme von Artikeln';
export const ARTICLE_ENABLE_DISPOSAL = 'Entsorgung von Artikeln';
export const ARTICLE_SCAN_AMOUNT = 'Menge nach Auswahl/Scan mit x vorbesetzen';
export const ARTICLE_SCAN_EAN_AMOUNT_IF_LARGER =
  'Menge bei Scan per EAN aus EAN-Vorschlag ermitteln wenn größer x (-1 zum Deaktivieren)';
export const ARTICLE_COMMENT_ON_SCRAPPING =
  'Kommentar bei Entsorgung/Rücknahme erforderlich?';

/**
 * Schadenserfassung
 */
export const DAMAGE_REPORTS_ORDER = 'Schadenserfassung an Aufträgen';
export const DAMAGE_REPORTS_MACHINE = 'Schadenserfassung an Maschinen';
export const DAMAGE_REPORTS_LISTED_MASTERDATA =
  'Schadensmeldungen als Übersicht unter Stammdaten';
export const DAMAGE_REPORTS_SIGNATURES =
  'Unterschriften unter Schadensmeldungen';
export const DAMAGE_REPORTS_SHOW_PDF =
  'PDF der Schadensmeldungen nach Abschluss darstellen';

/**
 * ChecklistManagement
 */
export const CHECKLISTS_SIGNATURES_NEEDED =
  'Unterschriften in Checklisten benötigt?';
export const CHECKLISTS_RENDER_PDF =
  'Zeige Checkliste als PDF dem Benutzer an?';
export const CHECKLISTS_SIGNATURE_TEXT_CUSTOMER_NUMBER =
  'Kundennummer unter Unterschrift (Auftraggeber) vorausfüllen';
export const CHECKLISTS_NO_SIGNATURE_CUSTOMER_IF_NAMED =
  'Unterschrift (Auftraggeber) entfällt wenn Auftraggeber-Name ausgefüllt';
export const CHECKLISTS_CUSTOMER_SIGNATURE_ONLY_TECHNICAL =
  'Unterschrift (Auftraggeber) nur wenn Reparaturen durch Techniker';
export const CL_CONFIRMATION_BEFORE_COMPLETION =
  'Checklisten vor Abschluss bestätigen';

/**
 * Serviceaufträge
 */
export const USER_CAN_ADD_ONLY_ONE_MACHINE =
  'Maximal eine Maschine pro Auftrag';
export const CAN_ADD_MACHINE =
  'Aussendienst kann neue Maschinen im Auftrag hinzufügen';
export const ONLY_SCAN_MACHINE = 'Auftragsmaschine nur Scanbar';
export const DISTANCE_EDITABLE = 'Gefahrene Kilometer veränderbar Aussendienst';
export const TIME_EDITABLE = 'Erfasste Arbeitszeit veränderbar Aussendienst';
export const SHOW_ARTICLE_AMOUNT_POSITIONS =
  'Gesamtmenge aller Artikel an allen Positionen einer Maschine zeigen';
export const CAN_CANCEL_REPORT_WHEN_FINISHED =
  'Abbrechen einer Maschine möglich wenn diese schon Abgeschlossen wurde?';
export const SHOW_ORDERNUMBER_IN_OVERVIEW =
  'Auftragsnummer in Auftragsübersicht anzeigen';
export const SHORTEN_ORDERNUMBER_IN_OVERVIEW =
  'Auftragsnummer in Auftragsübersicht abkürzen';
export const SHOW_CANCEL_ORDER_ALERT_ON_SIGNOUT =
  'Abbrechen von Aufträgen ermöglichen beim Abmelden vom Fahrzeug';
export const SA_SIGNATURE_TEXT_CUSTOMER_NUMBER =
  'Kundennummer unter Unterschrift (Auftraggeber) vorausfüllen (SA)';
export const SA_NO_SIGNATURE_CUSTOMER_IF_NAMED =
  'Unterschrift (Auftraggeber) entfällt wenn Auftraggeber-Name ausgefüllt (SA)';
export const SA_CUSTOMER_SIGNATURE_ONLY_TECHNICAL =
  'Unterschrift (Auftraggeber) nur wenn Reparaturen durch Techniker (SA)';
export const SA_SIGNATURES_NEEDED =
  'Unterschriften in Serviceauftrag benötigt?';
export const SA_CONFIRM_ORDER_COMPLETION =
  'Auftragsende nach Unterschrift bestätigen?';
export const SA_RENDER_PDF_BEFORE_SIGN =
  'Zeige Serviceauftrag vor der Unterschrift als PDF dem Benutzer an?';
export const SA_RENDER_PDF_AFTER_SIGN =
  'Zeige Serviceauftrag nach der Unterschrift als PDF dem Benutzer an?';
export const SA_SEGMENT_DONE_ACTIVE =
  'Zeige das Segment "Erledigt" in der Auftragsübersicht an?';
export const SHOW_CHECKLISTS_ON_FILLER_ORDER =
  'Checklisten an Befüllungsauftrag anzeigen?';
export const SA_DESCRIPTION_NEEDED =
  'Auftragsbeschreibung / Tätigkeit Pflichfeld';
export const SA_ENABLE_OPERATING_HOURS =
  'Gesamtzählerstand an Auftragsmaschine';
export const SA_ENABLE_REFUND = 'Kundenrückerstattung an Auftragsmaschine';
export const SA_ENABLE_CASH_FLOW = 'Wechselgeldbefüllung an Auftragsmaschine';
export const SA_ENABLE_CASH_BAG = 'Bargeldverwaltung an Auftragsmaschine';
export const SA_ENABLE_MASCHINE_HYGIENE_CHECKLISTS =
  'Hygiene-Checklisten an Auftragsmaschine';
export const SA_ENABLE_CHECKLISTS = 'Checklisten am Auftrag';
export const SA_ENABLE_MASCHINE_CHECKLISTS = 'Checklisten an Auftragsmaschine';
export const SA_ENABLE_POSITIONS = 'Positionen am Auftrag';
export const SA_ENABLE_MACHINE_POSITIONS = 'Positionen an Auftragsmaschine';
export const SA_ARTICLE_FORCE_PLANOGRAM =
  'Positionen an Aufträgen nur mit Artikeln aus dem Planogramm möglich';
export const SA_IMPLEMENTED_WORK_ON_MACHINE =
  'Möglichkeit abgeschlossene Arbeiten an der Maschine zu erfassen';
export const SA_IMPLEMENTED_WORK =
  'Möglichkeit durchgeführte Arbeiten am Auftrag zu erfassen';
export const SA_DESCRIPTION_ON_MACHINE =
  'Möglichkeit durchzuführende Arbeiten an der Maschine zu erfassen';
export const SA_FUTURE_WORK_ON_MACHINE =
  'Möglichkeit ausstehende Arbeiten Arbeiten an der Maschine zu erfassen';
export const SA_FUTURE_WORK =
  'Möglichkeit ausstehende Arbeiten Arbeiten am Auftrag zu erfassen';
export const SA_CAN_BE_CREATED =
  'Serviceaufträge über Seitenmenü direkt erstellen können';
export const DISABLE_PAUSE_BUTTON =
  'Pause-Button bei Serviceaufträgen deaktivieren';
export const SA_ASK_FOR_DIRECT_FINISH_IF_ONE_MACHINE =
  'Wenn nur eine Maschine in Auftrag, direkten Auftragsabschluss ermöglichen?';
export const SA_ALLOW_OVERPACK_ON_POSITIONS =
  'Eingabe von Umverpackungsmengen an Positionen ermöglichen';
export const SA_SHOW_MACHINE_NAME_ON_MACHINE =
  'Maschinenname im Titel der Bearbeitung einer Auftragsmaschine anzeigen';
export const SA_CONFIRMATION_BEFORE_ORDER_COMPLETION =
  'Bestätigungsabfrage vor Auftragsabschluss';
export const SA_FAST_CONFIRMATION_BEFORE_CREATE =
  'Schnelleinstieg > Bestätigung vor Auftragserstellung nötig';
export const SA_MONEYBAG_ONLY_ALLOW_NUMERIC_VALUES =
  'Geldsacknummer auf numerische Eingaben beschränken';
export const SA_MONEYBAG_MIN_NUMERIC_VALUE =
  'Minimale Geldsacknummer';
export const SA_MONEYBAG_MAX_NUMERIC_VALUE =
  'Maximale Geldsacknummer';
export const SA_MONEYBAG_ALLOW_SECOND_MONEYBAG_NUMBER =
  'Erlauben einer zweiten Geldsacknummer';
export const SA_MONEYBAG_ASSURE_SCANNED_NUMBER_IS_NO_ARTICLE_EAN =
  'Validierung des Scanstrings als EAN-Code';
export const SA_DISABLE_SIGNATURES_IF_SERVICE_CONTRACT_ACTIVE =
  'Unterschriften in Serviceauftrag entfallen bei aktivem Servicevertrag'

/**
 * Zeiterfassung
 */
export const ZE_FOR_USER_OR_CAR = 'Benutzer- / Fahrzeugbezogene Zeiterfassung';
export const ZE_FORCE_TIMESTAMP = 'Zustandswechsel automatisch erzwingen bei ungültigem Zustandsübergang';
export const ZE_USE_MANUAL_INPUT_FROM_USER =
  'Zeiterfassung durch manuelle Eingabe der auf den eigenen Benutzer zu erfassenden Zeit';
export const ZE_ENABLE_CORRECTIONS =
  'Korrekturanfragen durch den Außendienst möglich?';
export const ZE_SHOW_TIMETRACKING_IN_MENU =
  'Zeiterfassung im Seitenmenü darstellen?';
export const ZE_AUTO_DAYSTART = 'Automatischer Tagesstart bei Login';
export const ZE_AUTO_DAYEND = 'Automatischer Tagesabschluss bei Logout';
export const ZE_AUTO_BUFFERSTOCK_START_END =
  '"Bufferstock rüsten" im Bufferstock automatisch starten und beenden';
export const ZE_AUTO_WORKING_AFTER_CRASH =
  'Nach App-Absturz automatisch in Arbeitszeit wechseln';
/**
 * Standort
 */
export const SO_USE_BUFFERSTOCKS =
  'Maschinenstandort muss einen Bufferstock besitzen?';

/**
 * Lager (Warehouse)
 */
export const LG_PHOTOS_OF_DELIVERY_NOTES_BY_ORDER =
  'Sollen Fotos von Lieferscheinen für die Bestellungen gemacht werden?';
export const LG_TRASH_ARTICLE_USE_SCRAP_SELECT =
  'Bei der Entsorgung von Artikeln, Freitext-Feld mit Entsorgungskategorie-Auswahl ersetzen';
export const LG_VERIFY_ORDER_OF_BUFFERSTOCK =
  'Bestellungen am Bufferstock müssen durch Innendienst verifiziert werden';
export const LG_ORDER_ONLY_IN_OVERPACKSIZE =
  'Bestellung nur in Umverpackungsmengen möglich';
export const LG_DISPOSAL_IN_ORDER_CONFIRMATION =
  'Entsorgungen in der Bestellungsbestätigung erfassen';
export const LG_BASKET_AMOUNT_INITAL_ZERO =
  'Mengen beim Starten einer neuen Bestellung vom Basket initial auf 0 setzen';
export const LG_BASKETS_CAN_GET_CREATED =
  'Können Baskets direkt am Bufferstock erstellt werden ?';
export const LG_USER_CAN_EDIT_DISPOSALS_ON_CLEANUP =
  'Benutzer darf Entsorgung - (Scrapping)-Liste aus Automaten anpassen';
export const LG_SHOW_ALL_BASKET_ARTICLES_IN_CLEANUP =
  'Bufferstock-Entsorgung: Anstelle von Bestands Artikeln alle Basketartikel zur Auswahl anzeigen';
export const LG_ACTIVATE_FUNCTIONS_ONLY_IF_BASKET =
  'Bufferstock Lagerfunktionen nur aktiv sofern mindestens. 1 Basket vorhanden ist';
export const LG_MACHINE_INVENTUR_USE_GIVEN_POSITIONS =
  'Inventur an der Maschine - Positionen aus Layout oder Planogram vorbelegen';

/**
 * Kunden
 */
export const CU_SHOW_OPEN_ORDERS_BUTTON =
  'Möglichkeit die offenen Serviceaufträge eines Kunden aufzulisten';

/**
 * Inventur
 */
export const INV_START_WITH_EMPTY_INVENTORY =
  'Inventur beginnt mit einer leeren Liste anstelle des aktuellen Bestands';
export const INV_SKIP_DISPOSAL_INVENOTRY =
  'Entsorgungslager bei der Inventur ausblenden und nicht inventarisieren';
export const INV_CONFIRM_ZERO_AMOUNTS =
  'Artikel mit der Menge 0 am Ende der Inventur durch den Zählenden bestätigen';
export const INV_CONFIRM_DIFFERNCE_PERCENTAGE =
  'Artikelmengen vor Abschluss der Inventur bei zu großen Abweichungen bestätigen';
export const INV_COUNTING_ALL_ARTICLES_REQUIRED =
  'Alle verfügbaren Artikel müssen gezählt werden';
export const INV_LOCK_BUFFERSTOCK_X_DAYS_BEFORE_INVENTORY =
  'Bufferstock wird x Tage vor der Inventur gesperrt';

/**
 * PDF Export
 */
export const PDF_EXPORT_SERVICE_ORDER = 'PDF Serviceauftrag erstellen';
export const PDF_EXPORT_CHECKLIST_SERVICE_ORDER =
  'PDF Checkliste-Serviceauftrag erstellen';
export const PDF_EXPORT_CHECKLIST_MACHINE = 'PDF Checkliste-Maschine erstellen';
export const PDF_EXPORT_CHECKLIST_HYGIENE = 'PDF Checkliste-Hygiene erstellen';
export const PDF_EXPORT_CHECKLIST_WORK_STEP =
  'PDF Checkliste-Arbeitsschritt erstellen';
export const PDF_EXPORT_DAMAGE_REPORT = 'PDF Schadenmeldungen erstellen';
